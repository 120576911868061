import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms'
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core'

import { fromEvent, combineLatest, of } from 'rxjs'
import { untilDestroyed } from 'ngx-take-until-destroy'
import { debounceTime, filter, first, tap, catchError } from 'rxjs/operators'

import { RecaptchaService } from '../../services'

const CUTOFF_SCORE = 0.5

@Component({
    selector: 'app-recaptcha-button',
    templateUrl: './recaptcha-button.component.html',
    styleUrls: ['./recaptcha-button.component.scss']
})
export class RecaptchaButtonComponent implements OnInit, OnDestroy, AfterViewInit {
    private static idCounter = 0
    private score = 0
    private alreadyClicked = false
    private formCtrl: AbstractControl
    isLoading = false
    isDisabled = true
    id = `recaptcha-button-${RecaptchaButtonComponent.idCounter++}`
    @ViewChild('recaptchaButton') recaptchaButton: ElementRef<HTMLLabelElement>
    @ViewChild('recaptchaButtonLabel') recaptchaButtonLabel: ElementRef<HTMLLabelElement>
    @Input() formRoot: FormGroup
    @Input() action: string

    constructor(public recaptchaService: RecaptchaService) { }

    ngOnInit() {
        this.formRoot.addControl(this.id, new FormControl({value: false, disabled: true}, Validators.requiredTrue))
        this.formCtrl = this.formRoot.get(this.id)
    }

    ngAfterViewInit() {
        combineLatest(
            this.recaptchaService.execute(this.action).pipe(
                catchError(() => of({score: 0}))
            ),
            this.formCtrl.root.statusChanges.pipe(
                filter(s => s === 'VALID'),
                first(),
                tap(() => {
                    this.formCtrl.enable()
                })
            )
        ).pipe(first()).subscribe(([{score}]) => {
            this.score = score || this.score
            this.enable()
        })

        fromEvent(this.recaptchaButtonLabel.nativeElement, 'click').pipe(
            tap((e: Event) => {
                e.preventDefault()
                this.isLoading = true
            }),
            debounceTime(1000),
            untilDestroyed(this)
        ).subscribe(() => {
            if (!this.alreadyClicked) {
                this.alreadyClicked = true
                this.recaptchaButton.nativeElement.click()
                this.formCtrl.disable()
            }
        })
    }

    ngOnDestroy() { }

    enable() {
        if (this.isDisabled) {
            if (this.score > CUTOFF_SCORE) { this.formCtrl.disable() } else { this.isDisabled = false }
        }
    }
}

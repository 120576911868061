import { Router } from '@angular/router'
import { DOCUMENT } from '@angular/common'
import { Component, OnInit, Input, Inject, TemplateRef, Renderer2, Output, EventEmitter } from '@angular/core'

import { State } from '../morph-button/morph-button.model'

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

    @Input() size: string
    @Input() hasCloseButton = true
    @Input() routeOnClose: string
    @Input() modalHeader: TemplateRef<any>
    @Input() modalBody: TemplateRef<any>
    @Input() modalFooter: TemplateRef<any>
    @Output() onClose = new EventEmitter()

    isOpen = false
    sizeClass = ''
    State = State

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private renderer: Renderer2,
        private router: Router
    ) {}

    ngOnInit() {
        if (this.size) { this.sizeClass = `c-modal--${this.size}` }
    }

    openModal() {
        this.isOpen = true
        this.renderer.addClass(document.body, 'document--modal')
    }

    closeModal() {
        this.isOpen = false
        this.renderer.removeClass(document.body, 'document--modal')
        if (this.routeOnClose) { this.router.navigateByUrl(this.routeOnClose) }
        this.onClose.emit()
    }
}

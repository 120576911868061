import { Meta, Title } from '@angular/platform-browser'
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router'
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core'

import { filter, map, mergeMap } from 'rxjs/operators'
import { untilDestroyed } from 'ngx-take-until-destroy'

import { AnalyticsService } from './shared/services'
import { TranslatorService } from './core/translator/translator.service'

const INDEX_STATIC = 'Mediacoach | '

@Component({
    selector: 'app-root',
    styleUrls: ['./app.component.scss'],
    templateUrl: './app.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
    constructor(
        public analyticsServices: AnalyticsService,
        public router: Router,
        private meta: Meta,
        private translatorService: TranslatorService,
        private activatedRoute: ActivatedRoute,
        private titleService: Title
    ) {
        // In this function we are accessing the root of the routes to access the description of the index
        this.setTitleOrDescriptionToIndex(true)
        this.router.events.pipe(untilDestroyed(this)).subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.analyticsServices.trackPage(event.urlAfterRedirects)
            }
        })
    }

    ngOnInit() {
        // In this function we are going through the children of the route to access the title of each page
        this.setTitleOrDescriptionToIndex(false)
    }

    setTitleOrDescriptionToIndex(root) {
        this.router
            .events
            .pipe(
                filter(e => e instanceof NavigationEnd),
                map(() => this.activatedRoute),
                map(route => {
                    if (root && route.firstChild) {
                        route = route.firstChild
                    } else {
                        while (route.firstChild) { route = route.firstChild }
                    }
                    return route
                }),
                filter(route => route.outlet === 'primary'),
                mergeMap(route => route.data),
                untilDestroyed(this)
            )
            .subscribe(event => {
                if (event['title']) {
                    this.translatorService.stream(event['title']).pipe(
                        untilDestroyed(this)
                    ).subscribe((text) => (this.titleService.setTitle(INDEX_STATIC + text)))
                  }
                if (event['description']) {
                    if ( this.meta.getTags('name=description')) {
                        this.translatorService.stream(event['description']).pipe(
                            untilDestroyed(this)
                        ).subscribe((text) => (this.meta.updateTag({ name: 'description', content: text })))
                    } else {
                        this.translatorService.stream(event['description']).pipe(
                            untilDestroyed(this)
                        ).subscribe((text) => (this.meta.addTag({ name: 'description', content: text })))
                    }
                  }
            })
    }

    ngOnDestroy() {}
}

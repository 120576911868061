import { Injectable } from '@angular/core'

declare const ga: any


@Injectable()
export class AnalyticsService {

    constructor() { }


    public trackUser(infoUser: string) {
        ga('set', 'userId', infoUser)
    }

    public trackPage(url: string) {
        ga('set', 'page', url)
        ga('send', 'pageview')
    }

    public trackEvent(eventCategory: string, eventAction: string, eventLabel: string = null, eventValue: number = null) {
        ga('send', 'event', {
            eventCategory,
            eventLabel,
            eventAction,
            eventValue
        })
    }

}

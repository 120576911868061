import { Validators, FormGroup, FormBuilder } from '@angular/forms'
import { Component, OnInit, ViewEncapsulation, ViewChild, AfterViewInit } from '@angular/core'

import { DialogLegalComponent } from '../../../shared/components'
import { MailService, UtilitiesService } from '../../../shared/services'

const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ContactComponent implements OnInit, AfterViewInit {
    @ViewChild('privacy') public privacy: DialogLegalComponent
    @ViewChild('conditions') public conditions: DialogLegalComponent

    showMessage = false
    messageContact: string
    imageChecked
    contactForm: FormGroup

    constructor(
        private mailService: MailService,
        private fb: FormBuilder,
        public utility: UtilitiesService
    ) {
        this.imageChecked = utility.setStyle('img/check-mark.png')
    }

    ngOnInit() {
        this.buildForm()
    }

    ngAfterViewInit() {
        this.utility.subir()
    }

    buildForm() {
        this.contactForm = this.fb.group({
            name: ['', [Validators.required, Validators.maxLength(100)]],
            email: ['', [Validators.required, Validators.maxLength(100), Validators.pattern(EMAIL_REGEX)]],
            message: ['', [Validators.required, Validators.maxLength(1000)]],
            isCheckCondicionesLegales: [false, [Validators.requiredTrue]]
        })
    }

    onSubmit() {
        this.mailService.sendContactRequest(this.contactForm.value).subscribe(() => {
            this.messageContact = 'CONTACT_FORM_LAB_FEEDBACK'
            this.showMessage = true
            this.clearForm()
        }, () => {
            this.messageContact = 'CONTACT_FORM_LAB_FEEDBACK_ERROR'
           this.showMessage = true
        })
    }

    openModal(type) {
        this[type].openModal()
    }

    clearForm() {
        this.contactForm.reset()
    }
}

import { Component, OnInit, ViewChild, Input } from '@angular/core'
import { ModalComponent } from '../modal/modal.component'

@Component({
    selector: 'app-dialog-legal',
    templateUrl: './dialog-legal.component.html',
    styleUrls: ['./dialog-legal.component.scss'],
})
export class DialogLegalComponent implements OnInit {
    @ViewChild('legal') public modal: ModalComponent
    @Input() type

    constructor() {
    }

    ngOnInit() {
    }

    openModal() {
        this.modal.openModal()
    }
    closeModal() {
        this.modal.closeModal()
    }
}

import {Component, ViewEncapsulation, ViewChild, AfterViewInit} from '@angular/core'
import { DialogComponent } from '../../dialogs/dialog-demo/dialog-demo.component'
import { UtilitiesService } from '../../../../shared/services/utilities.service'

@Component({
    selector: 'app-product-mediacoach-live',
    templateUrl: './product-mediacoach-live.component.html',
    styleUrls: ['./product-mediacoach-live.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProductMediacoachLiveComponent implements AfterViewInit {
    @ViewChild('dialog') public dialog: DialogComponent

    urlStatics: string
    slideImage: string
    slides = [{
        title: 'MCLIVE_ADVANTAGES_LAB_SLIDER_1_MAIN',
        text: 'MCLIVE_ADVANTAGES_LAB_SLIDER_1_SUB',
        subtext: 'MCLIVE_ADVANTAGES_LAB_SLIDER_1_SUB2'
    }, {
        title: 'MCLIVE_ADVANTAGES_LAB_SLIDER_2_MAIN',
        text: 'MCLIVE_ADVANTAGES_LAB_SLIDER_2_SUB',
        subtext: 'MCLIVE_ADVANTAGES_LAB_SLIDER_2_SUB2'
    }, {
        title: 'MCLIVE_ADVANTAGES_LAB_SLIDER_3_MAIN',
        text: 'MCLIVE_ADVANTAGES_LAB_SLIDER_3_SUB',
        subtext: 'MCLIVE_ADVANTAGES_LAB_SLIDER_3_SUB2'
    }]

    constructor(public utility: UtilitiesService) {
        this.urlStatics = utility.assets
        this.slideImage = this.urlStatics + 'img/group-6.png'
    }

    ngAfterViewInit() {
        this.utility.subir()
    }

    showDialog(value: number) {
        this.dialog.showDialog(value)
    }
}

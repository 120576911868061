import { EventEmitter, Injectable } from '@angular/core'

import { Observable } from 'rxjs/Rx'
import { LangChangeEvent, TranslateService } from '@ngx-translate/core'

import { environment } from '../../../environments/environment'

@Injectable()
export class TranslatorService {
    onLangChange: EventEmitter<LangChangeEvent>

    constructor(private translateService: TranslateService) {
        this.onLangChange = this.translateService.onLangChange
        const lang = localStorage.getItem('currentLang') || environment.I18N_DEFAULT_LANG

        translateService.addLangs(environment.I18N_LANGS.map(({id}) => id))
        translateService.setDefaultLang(environment.I18N_DEFAULT_LANG)
        this.use(lang)

        this.onLangChange.subscribe(({lang: _lang}) => localStorage.setItem('currentLang', _lang))
    }

    getCurrentLang(localeFormat = false): string {
        const lang = this.translateService.currentLang
        return !localeFormat ? lang : `${lang}-${lang.toUpperCase()}`
    }

    getLanguages(): Array<string> { return this.translateService.getLangs() }

    use(lang: string): Observable<any> { return this.translateService.use(lang) }

    stream(key: string | Array<string>, interpolateParams?: Object): Observable<string | any> {
        return this.translateService.stream(key, interpolateParams)
    }

}

import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import localeEs from '@angular/common/locales/es'
import { registerLocaleData } from '@angular/common'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

import { AppComponent } from './app.component'
import { CoreModule } from './core/core.module'
import { PagesModule } from './pages/pages.module'

registerLocaleData(localeEs)

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        CoreModule,
        RouterModule,
        PagesModule,
        BrowserAnimationsModule
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

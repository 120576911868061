import { Directive, Input, ElementRef, OnInit, OnDestroy, AfterViewInit, Renderer2 } from '@angular/core'

import { timer } from 'rxjs'
import { Subscription } from 'rxjs'
import { untilDestroyed } from 'ngx-take-until-destroy'

import { ScrollService } from '../services'

@Directive({
    selector: '[appInViewport]'
})
export class InViewportDirective implements OnInit, OnDestroy, AfterViewInit {
    private isVisible = false
    private offsetTop: number
    private winHeight: number
    private classArray: string[]
    private scrollSub: Subscription = new Subscription()
    private resizeSub: Subscription = new Subscription()

    @Input() appInViewport: string // use fadeIn as default if not specified
    // Pixel offset from screen bottom to the animated element to determine the start of the animation
    @Input() offset = 80

    constructor(private elementRef: ElementRef, private renderer: Renderer2, private scroll: ScrollService) {}

    /**
     * check for visibility of element in viewport to add animation
     *
     * @returns void
     */
    private manageVisibility(): void {
        // Optimisation; nothing to do if class has already been applied
        if (this.isVisible) { return }

        // check for window height and get vertical position for selected element
        this.getWinHeight()
        this.getOffsetTop()

        // we should trigger the addition of the animation class a little after getting to the element
        const scrollTrigger = this.offsetTop + this.offset - this.winHeight

        // using values updated in service
        if (this.scroll.pos >= scrollTrigger) { this.toggleAnimationClass() }
    }

    /**
     * utility function to mark element visible and add css class
     *
     * @returns void
     */
    private toggleAnimationClass(): void {
        this.isVisible = true
        this.classArray.forEach((className) => this.renderer.addClass(this.elementRef.nativeElement, className))
    }

    /**
     * get window height utility function
     *
     * @returns void
     */
    private getWinHeight(): void {
        this.winHeight = window.innerHeight
    }

    /**
     * get offsetTop value for element
     *
     * @returns void
     */
    private getOffsetTop(): void {
        const viewportTop = this.elementRef.nativeElement.getBoundingClientRect().top
        const clientTop = this.elementRef.nativeElement.clientTop

        // get vertical position for selected element
        this.offsetTop = viewportTop + this.scroll.pos - clientTop
    }

    ngOnInit(): void {
        if (!this.appInViewport) {
            throw new Error('animationName required')
        } else {
            this.classArray = this.appInViewport.replace(/\s/g, ' ').trim().split(/\s/)
        }

        // subscribe to scroll and resize event using service
        this.scrollSub = this.scroll.scrollObs.pipe(untilDestroyed(this)).subscribe(() => this.manageVisibility())
        this.resizeSub = this.scroll.resizeObs.pipe(untilDestroyed(this)).subscribe(() => this.manageVisibility())
    }

    ngOnDestroy(): void { }

    ngAfterViewInit(): void {
        // run visibility check initially in case the element is already visible in viewport
        // setTimeout(() => this.manageVisibility(), 1)
        timer(100).subscribe(() => this.manageVisibility())

    }
}

import { Component, OnInit } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'

import { UtilitiesService } from '../../../shared/services'

@Component({
    selector: 'app-page-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent {

    urlStatics: string

    constructor(
        public _DomSanitizationService: DomSanitizer,
        public utilitiesService: UtilitiesService) {
            this.urlStatics = utilitiesService.assets
     }
}

import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { MatInputModule } from '@angular/material'
import { CommonModule, DatePipe } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

import { InfiniteScrollModule } from 'ngx-infinite-scroll'
import {
    TooltipModule,
    DropdownModule,
    CheckboxModule,
    DialogModule,
    FileUploadModule,
    MultiSelectModule,
    AutoCompleteModule,
    AccordionModule
} from 'primeng/primeng'

import { TranslatorModule } from '../core/translator/translator.module'

import {
    DialogLegalComponent,
    LegalTemplateComponent,
    LoadingComponent,
    ModalComponent,
    MorphButtonComponent,
    RecaptchaButtonComponent,
    TextCarouselComponent,
    TextItemComponent
} from './components'

import {
    EncryptedEmailDirective,
    InViewportDirective
} from './directives'

import { SafePipe } from './pipes/safe.pipe'

import {
    AnalyticsService,
    LocalStorageService,
    MailService,
    RecaptchaService,
    ScrollService,
    UtilitiesService
} from './services'

@NgModule({
    imports: [
        AutoCompleteModule,
        BrowserAnimationsModule,
        CheckboxModule,
        CommonModule,
        DialogModule,
        TooltipModule,
        DropdownModule,
        FileUploadModule,
        InfiniteScrollModule,
        FormsModule,
        MatInputModule,
        MultiSelectModule,
        ReactiveFormsModule,
        RouterModule,
        TranslatorModule,
        AccordionModule,
    ],
    declarations: [
        SafePipe,
        LoadingComponent,
        ModalComponent,
        LegalTemplateComponent,
        MorphButtonComponent,
        InViewportDirective,
        EncryptedEmailDirective,
        RecaptchaButtonComponent,
        TextCarouselComponent,
        TextItemComponent,
        DialogLegalComponent
    ],
    providers: [
        SafePipe,
        DatePipe,
        ScrollService,
        UtilitiesService,
        AnalyticsService,
        RecaptchaService,
        LocalStorageService,
        MailService
    ],
    exports: [
        SafePipe,
        AutoCompleteModule,
        TooltipModule,
        LoadingComponent,
        BrowserAnimationsModule,
        CheckboxModule,
        CommonModule,
        DialogModule,
        InViewportDirective,
        EncryptedEmailDirective,
        DropdownModule,
        FileUploadModule,
        InfiniteScrollModule,
        FormsModule,
        MatInputModule,
        MultiSelectModule,
        ModalComponent,
        LegalTemplateComponent,
        MorphButtonComponent,
        ReactiveFormsModule,
        RouterModule,
        TranslatorModule,
        RecaptchaButtonComponent,
        TextCarouselComponent,
        TextItemComponent,
        DialogLegalComponent,
        AccordionModule,
    ]
})
export class SharedModule { }

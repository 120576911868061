import { Component } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'

import { UtilitiesService } from '../../../shared/services'

@Component({
    selector: 'app-contact-support',
    templateUrl: './contact-support.component.html',
    styleUrls: ['./contact-support.component.scss']
})
export class ContactSupportComponent {

    urlStatics: string

    constructor(
        public utilitiesService: UtilitiesService,
        public _DomSanitizationService: DomSanitizer) {
            this.urlStatics = utilitiesService.assets
        }
}

export enum EnvironmentType {
    // azure
    Dv = 'dv',
    Qa = 'qa',
    Ts = 'ts',
    Pr = 'pr',
    // aws
    Dev = 'dev',
    Test = 'test',
    Test01 = 'test01',
    Test02 = 'test02',
    Pre = 'pre',
    Prod = ''
}

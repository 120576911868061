import { Injectable } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'

import { environment } from '../../../environments/environment'

@Injectable()
export class UtilitiesService {
    assets: string

    constructor(
        public _DomSanitizationService: DomSanitizer) {
        this.assets = environment.STATICS_URL_ASSETS
     }

    subir() {
        window.scrollTo(0, 0)
    }
    setStyle(image: string) {
        const securityImage = this._DomSanitizationService.bypassSecurityTrustUrl(this.assets + image)

        return {
            'background-image': 'url(\'' + securityImage['changingThisBreaksApplicationSecurity'] + '\')'
        }
    }

    /**
     * Returns an array between start and end value, both inclusive
     *
     * @param start {number}
     * @param end {number}
     *
     * @returns {number[]}
     */
    range(start, end) {
        const size = end - start
        const sign = size > 0 ? 1 : -1
        return Array(Math.abs(size) + 1).fill(1).map((v, i) => start + (i * sign))
    }
}

import { Injectable } from '@angular/core'

const STRING_EXP = /^"(.*)"$/

@Injectable()
export class LocalStorageService {

    constructor() { }

    private getValue (value) {
        let result = value === 'undefined' ? undefined : (value === 'null' ? null : value)
        try {
            result = JSON.parse(decodeURIComponent(value))
        } catch (error) {}
        return result
    }

    private setValue (value = 'undefined') {
        value = JSON.stringify(value)
        if (value.match(STRING_EXP)) { value = value.replace(STRING_EXP, '$1') }
        return encodeURIComponent(value)
    }

    get (key) {
        return this.getValue(localStorage.getItem(key))
    }

    set (key, value) {
        if (!key) { return }
        localStorage.setItem(key, this.setValue(value))
    }
}

import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import {
    ContactComponent,
    CookiesPolicyComponent,
    HomeComponent,
    LoginComponent,
    PageNotFoundComponent,
    ProductMediacoachDesktopComponent,
    ProductMediacoachLiveComponent,
    ProductMediacoachPortalComponent,
    ProductsCenterHomeComponent
} from './pages/components'

const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
        data: {
            description: 'COMMONS_TITLE_DESCRIPTION', title: 'COMMONS_TITLE_COMERCIAL_WEB'
        }
    },
    {
        path: 'productos',
        component: ProductsCenterHomeComponent,
    },
    {
        path: 'productos/mediacoachdesktop',
        component: ProductMediacoachDesktopComponent,
    },
    {
        path: 'productos/mediacoachlive',
        component: ProductMediacoachLiveComponent,
    },
    {
        path: 'productos/mediacoachportal',
        component: ProductMediacoachPortalComponent,
    },
    {
        path: 'contacto',
        component: ContactComponent,
    },
    {
        path: 'cookies',
        component: CookiesPolicyComponent
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: '**',
        component: PageNotFoundComponent
    }
]

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: []
})
export class AppRoutingModule {
}

import { DomSanitizer } from '@angular/platform-browser'
import { AfterViewInit, Component, ViewChild, ViewEncapsulation } from '@angular/core'

import { DialogComponent } from '../../dialogs/dialog-demo/dialog-demo.component'
import { UtilitiesService } from '../../../../shared/services'

@Component({
    templateUrl: './products-center-home.component.html',
    styleUrls: ['./products-center-home.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ProductsCenterHomeComponent implements AfterViewInit {
    @ViewChild('dialog') public dialog: DialogComponent

    urlStatics: string

    constructor(
        public utility: UtilitiesService,
        public _DomSanitizationService: DomSanitizer) {
            this.urlStatics = utility.assets
         }

    ngAfterViewInit() {
        this.utility.subir()
    }

    showDialog(value: number) {
        this.dialog.showDialog(value)
    }
}

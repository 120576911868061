import { NgModule } from '@angular/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { HttpClient, HttpClientModule } from '@angular/common/http'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'

import { TranslatorService } from './translator.service'
import { environment } from '../../../environments/environment'

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, environment.STATICS_URL_ASSETS + 'i18n/', '.json')
}

@NgModule({
    imports: [
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (HttpLoaderFactory),
                deps: [HttpClient],
            },
            isolate: false
        })
    ],
    declarations: [],
    exports: [TranslateModule],
    providers: [TranslatorService]
})
export class TranslatorModule {
    constructor() {}
}

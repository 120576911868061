import { DomSanitizer } from '@angular/platform-browser'
import { AfterViewInit, Component, ViewChild } from '@angular/core'

import { DialogComponent } from '../dialogs/dialog-demo/dialog-demo.component'
import { UtilitiesService } from '../../../shared/services'
@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})

export class HomeComponent implements AfterViewInit {
    @ViewChild('dialog') public dialog: DialogComponent
    urlStatics: string

    constructor(public utility: UtilitiesService, public _DomSanitizationService: DomSanitizer) {
        this.urlStatics = utility.assets
    }

    ngAfterViewInit() {
        this.utility.subir()
    }

    showDialog(value: number) {
        this.dialog.showDialog(value)
    }
}

import { DomSanitizer } from '@angular/platform-browser'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core'

import { environment } from '../../../../../environments/environment'
import { MailService, UtilitiesService } from '../../../../shared/services'
import { ModalComponent, DialogLegalComponent } from '../../../../shared/components'

const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
const DEMO_PRODUCTS = [{
    key: 'desktop',
    title: 'MEDIACOACH DESKTOP',
    img: `${environment.STATICS_URL_ASSETS}img/i-mac.svg`
}, {
    key: 'live',
    title: 'MEDIACOACH LIVE',
    img: `${environment.STATICS_URL_ASSETS}img/img-ipad.svg`
}]

@Component({
    selector: 'app-dialog-demo',
    templateUrl: './dialog-demo.component.html',
    styleUrls: ['./dialog-demo.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DialogComponent implements OnInit {
    @ViewChild('demo') public modal: ModalComponent
    @ViewChild('privacy') public privacy: DialogLegalComponent
    @ViewChild('conditions') public conditions: DialogLegalComponent

    DEMO_PRODUCTS = DEMO_PRODUCTS

    showFormDemo = false
    showMessageOK = true
    messageDemo: string
    urlStatics: string
    demoForm: FormGroup

    imageChecked

    constructor(
        private mailService: MailService,
        private fb: FormBuilder,
        public utilitiesService: UtilitiesService,
        public _DomSanitizationService: DomSanitizer) {
        this.urlStatics = utilitiesService.assets
        this.imageChecked = utilitiesService.setStyle('img/check-mark.png')
    }

    ngOnInit() {
        this.buildForm()
    }

    buildForm() {
        this.demoForm = this.fb.group({
            name: ['', [Validators.required, Validators.maxLength(100)]],
            email: ['', [Validators.required, Validators.maxLength(100), Validators.pattern(EMAIL_REGEX)]],
            productId: [0],
            comments: [''],
            isCheckCondicionesLegales: [false, [Validators.requiredTrue]]
        })
    }

    onSubmit() {
        this.showFormDemo = true
        this.showMessageOK = false

        this.mailService.sendDemoRequest(this.demoForm.value).subscribe(() => {
            this.messageDemo = 'DEMO_FORM_LAB_FEEDBACK'
            this.clearForm()
        }, error => {
            this.messageDemo = 'DEMO_FORM_LAB_FEEDBACK_ERROR'
            this.clearForm()
        })
    }

    showDialog(value?: number) {
        this.demoForm.get('productId').setValue(value)
        this.modal.openModal()
    }

    openModal(type) {
        this[type].openModal()
    }

    closeModalDemo() {
        this.modal.closeModal()
        this.showFormDemo = false
        this.showMessageOK = true
        this.messageDemo = null
        this.clearForm()
    }

    clearForm() {
        this.demoForm.reset()
    }
}

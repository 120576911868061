import {AfterViewInit, Directive, ElementRef, Input, OnDestroy} from '@angular/core'
import {TranslatorService} from '../../core/translator/translator.service'
import {untilDestroyed} from 'ngx-take-until-destroy'

@Directive({
  selector: '[appEncryptedEmail]'
})
export class EncryptedEmailDirective implements AfterViewInit, OnDestroy {
    @Input() encryptedEmail: string

    constructor(private _elRef: ElementRef, private translatorService: TranslatorService) {
        this.setData('')
    }

    private setData(emailText) {
        const [main, brand, ext] = emailText.replace(/(.+)@(.+)\.(.+)/, '$1**$2**$3').split('**')
        const dataset = this._elRef.nativeElement.dataset
        dataset.main = main ? main + '@' : ''
        dataset.brand = brand ? brand + '.' : ''
        dataset.ext = ext || ''
    }

    ngAfterViewInit() {
        this.translatorService.stream(this.encryptedEmail).pipe(
            untilDestroyed(this)
        ).subscribe((text) => this.setData(text))
    }

    ngOnDestroy() { }
}

export enum NavItemType {
    General = 'general',
    LogIn = 'login'
}

export interface NavItem {
    title: string,
    link: string[],
    type: NavItemType,
    linkActive: string[],
    dataNumber: string,
    extraClasses?: string
}

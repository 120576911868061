import { EnvironmentType } from './environment.model'

const MAIN_APP_NAME = 'portal'
const MAIN_APP_API = 'portal-api'
const PORTS = {
    APP: 4200
}

export const VARIABLES_BY_ENVIRONMENT = {
    [EnvironmentType.Dev]: {},
    [EnvironmentType.Test01]: {},
    [EnvironmentType.Test02]: {},
    [EnvironmentType.Pre]: {
        production: true,
        GOOGLE_ANALYTICS_TRACKING_CODE: 'UA-113083612-3'
    },
    [EnvironmentType.Prod]: {
        production: true,
        GOOGLE_ANALYTICS_TRACKING_CODE: 'UA-113371562-3'
    },
    [EnvironmentType.Dv]: {},
    [EnvironmentType.Qa]: {},
    [EnvironmentType.Ts]: {
        production: true,
        GOOGLE_ANALYTICS_TRACKING_CODE: 'UA-113083612-3'
    },
    [EnvironmentType.Pr]: {
        production: true,
        GOOGLE_ANALYTICS_TRACKING_CODE: 'UA-113083612-3'
    },
}

const AZURE_ENVS = [EnvironmentType.Dv, EnvironmentType.Qa, EnvironmentType.Ts, EnvironmentType.Pr]

const getEnvironmentInfo = (env: EnvironmentType) => {
    const { APP_DOMAIN, API_DOMAIN } = getDomainInfo(env)
    return {
        production: false,

        APP_DOMAIN: APP_DOMAIN,

        STATICS_URL_ASSETS: 'assets/',

        RECAPTCHA_SITE_KEY: '6Ld7xZ8UAAAAABoGqReo4tz4uMkK8oj0MrMIv1HS',

        DEBOUNCE_TIME: {
            FOR_CRASHES: 10,
            NORMAL: 100,
            INPUT_CHANGES: 100,
            CURSOR_CHANGES: 1000,
            LONG: 200
        },

        I18N_LANGS: [
            {id: 'es', locale: 'es-ES', i18nLabel: 'COMMONS_HEADER_OP_MENU_LANG_ES'},
            {id: 'en', locale: 'en-GB', i18nLabel: 'COMMONS_HEADER_OP_MENU_LANG_EN'}
        ],

        I18N_DEFAULT_LANG: 'es',

        GOOGLE_ANALYTICS_TRACKING_CODE: 'UA-108719301-3',

        API: getApiUrls(APP_DOMAIN, API_DOMAIN)
    }
}

const getDomainInfo = (env: EnvironmentType) => {
    const isDev = (env === EnvironmentType.Dev)
    const apiEnv = isDev ? EnvironmentType.Test01 : env
    return {
        APP_DOMAIN: isDev ? getUrlByPort('http://localhost', PORTS.APP) : getUrlByEnv(`https://${MAIN_APP_NAME}.mediacoach.es`, env),
        API_DOMAIN: getUrlByEnv(`https://${getApiDomain(env)}.mediacoach.es`, apiEnv),
    }
}

const getUrlByPort = (domain, port) => `${domain}${port === 80 ? '' : `:${port}`}`
const getUrlByEnv = (domain, env) => {
    const chunks = domain.split('.')
    chunks[0] += (env === EnvironmentType.Prod || env === EnvironmentType.Pr) ? '' : `-${env}`
    return chunks.join('.')
}
const getApiDomain = (env) => AZURE_ENVS.includes(env) ? MAIN_APP_API : MAIN_APP_NAME

const getApiUrls = (appDomain, apiDomain) => {
    const apiCommercialUrl = `${apiDomain}/api/comercial/`
    return {
        LOGIN_URL: `${appDomain}`,
        SEND_CONTACT_REQUEST: `${apiCommercialUrl}sendcontactrequest`,
        SEND_DEMO_REQUEST: `${apiCommercialUrl}senddemorequest`,
        RECAPTCHA_VERIFY: `${apiCommercialUrl}RecaptchaVerify`,
    }
}

export const getEnvironment = (env: EnvironmentType) => ({
    envType: env,

    ...getEnvironmentInfo(env),

    ...VARIABLES_BY_ENVIRONMENT[env],

    // Keep this comment untouched. Bamboo will replace it with the following
    // environment variables:
    //
    //     BUILD_VERSION, BUILD_NUM, BUILD_DATE, BUILD_BRANCH, BUILD_COMMIT
    //
    // {{ COMPILATION_VARS }}
})

import { AfterViewInit, Component } from '@angular/core'

import { BehaviorSubject, timer } from 'rxjs'
import { debounce, debounceTime, map } from 'rxjs/operators'

import { LocalStorageService } from '../../../shared/services'
import { environment } from '../../../../environments/environment'
import { State } from '../../../shared/components/morph-button/morph-button.model'
import { DialogLegalComponent } from 'app/shared/components/dialog-legal/dialog-legal.component'

const LS_ACCEPT_COOKIES = 'acceptCookies'

@Component({
    selector: 'app-cookies',
    templateUrl: './cookies.component.html',
    styleUrls: ['./cookies.component.scss']
})
export class CookiesComponent implements AfterViewInit {
    private _cookiesAccepted$ = new BehaviorSubject(false)
    public privacy: DialogLegalComponent

    showCookies$ = this._cookiesAccepted$.pipe(
        debounce((cookiesAccepted) => timer(!cookiesAccepted ? environment.DEBOUNCE_TIME.LONG : 0)),
        map((cookiesAccepted) => !cookiesAccepted)
    )
    removeCookies$ = this._cookiesAccepted$.pipe(
        debounceTime(1000)
    )
    State = State

    constructor(private localStorageService: LocalStorageService) {}

    ngAfterViewInit() {
        this._cookiesAccepted$.next(this.localStorageService.get(LS_ACCEPT_COOKIES))
    }

    acceptCookies() {
        this.localStorageService.set(LS_ACCEPT_COOKIES, true)
        this._cookiesAccepted$.next(true)
    }
}

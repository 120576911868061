import { NavItem, NavItemType } from './header.model'
import { environment } from '../../../../environments/environment'

export const NAV_CONFIG: NavItem[] = [
    {
        title: 'COMMONS_HEADER_OP_MENU_PRODUCTS',
        link: ['/productos'],
        type: NavItemType.General,
        linkActive: ['menu-active'],
        dataNumber: '1',
    },
    {
        title: 'COMMONS_HEADER_OP_MENU_CONTACT',
        link: ['/contacto'],
        type: NavItemType.General,
        linkActive: ['menu-active'],
        dataNumber: '3',
    },
    {
        title: 'COMMONS_HEADER_OP_MENU_LOGIN',
        link: [environment.API.LOGIN_URL],
        type: NavItemType.LogIn,
        linkActive: ['menu-active'],
        dataNumber: '4',
        extraClasses: 'login'
    }
]

import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import 'hammerjs'
import * as moment from 'moment'

import { AppModule } from './app/app.module'
import { environment } from './environments/environment'
import { EnvironmentType } from './environments/environment.model'

if (environment.production) {
    enableProdMode()
}

window['loadGoogleAnalytics'](environment.GOOGLE_ANALYTICS_TRACKING_CODE)

if (environment.envType === EnvironmentType.Prod || environment.envType === EnvironmentType.Pr) {
    window['insertBuildInfoNode'](
        environment['BUILD_VERSION'],
        environment['BUILD_NUM'],
        moment(environment['BUILD_DATE']).format('DD-MM-YYYY')
    )
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .then(success => console.log('Bootstrap success'))
  .catch(err => console.error(err))


import { NgModule } from '@angular/core'

import { CarouselModule } from 'primeng/carousel'

import { SharedModule } from '../shared/shared.module'
import { AppRoutingModule } from '../app-routing.module'

import {
    ContactComponent,
    ContactSupportComponent,
    ContactUsComponent,
    CookiesComponent,
    CookiesPolicyComponent,
    DialogComponent,
    FooterComponent,
    HeaderComponent,
    HomeComponent,
    LoginComponent,
    PageNotFoundComponent,
    ProductMediacoachDesktopComponent,
    ProductMediacoachLiveComponent,
    ProductMediacoachPortalComponent,
    ProductsCenterHomeComponent
} from './components'

@NgModule({
    imports: [
        SharedModule,
        CarouselModule,
        AppRoutingModule,
    ],
    declarations: [
        ContactComponent,
        ContactUsComponent,
        ContactSupportComponent,
        CookiesComponent,
        CookiesPolicyComponent,
        DialogComponent,
        FooterComponent,
        HeaderComponent,
        HomeComponent,
        PageNotFoundComponent,
        ProductsCenterHomeComponent,
        ProductMediacoachDesktopComponent,
        ProductMediacoachLiveComponent,
        ProductMediacoachPortalComponent,
        LoginComponent
    ],
    exports: [
        ContactComponent,
        ContactUsComponent,
        ContactSupportComponent,
        CookiesComponent,
        CookiesPolicyComponent,
        DialogComponent,
        FooterComponent,
        HeaderComponent,
        PageNotFoundComponent,
        ProductsCenterHomeComponent,
        ProductMediacoachDesktopComponent,
        ProductMediacoachLiveComponent,
        ProductMediacoachPortalComponent,
        LoginComponent
    ]
})
export class PagesModule { }

import { AfterViewInit, Component } from '@angular/core'

import { UtilitiesService } from '../../../shared/services'

@Component({
    selector: 'app-cookies-policy',
    templateUrl: './cookies-policy.component.html',
    styleUrls: ['./cookies-policy.component.scss']
})
export class CookiesPolicyComponent implements AfterViewInit{

    constructor(public utility: UtilitiesService) { }

    ngAfterViewInit() {
        this.utility.subir()
    }

}

import { Component, Input } from '@angular/core'
import { State, Color } from './morph-button.model'

@Component({
    selector: 'app-morph-button',
    templateUrl: './morph-button.component.html',
    styleUrls: ['./morph-button.component.scss'],
})
export class MorphButtonComponent {
    @Input() state: State
    @Input() color: Color

    constructor() {}
}

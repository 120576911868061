import {Component, Input, OnDestroy, OnInit} from '@angular/core'
import {timer} from 'rxjs/internal/observable/timer'
import {untilDestroyed} from 'ngx-take-until-destroy'
import {Subject} from 'rxjs'
import {startWith, switchMap} from 'rxjs/operators'


@Component({
    selector: 'app-text-carousel',
    templateUrl: './text-carousel.component.html',
    styleUrls: ['./text-carousel.component.scss'],
})

export class TextCarouselComponent implements OnInit, OnDestroy {
    private resetTimer$ = new Subject()
    @Input() timer = 5000
    @Input() image: string
    @Input() items: {title: string, text: string, subtext: string}[] = []
    selectedIndex = 0

    constructor() {}

    ngOnInit() {
        this.resetTimer$.pipe(
            startWith(undefined),
            switchMap(() => timer(this.timer, this.timer)),
            untilDestroyed(this)
        ).subscribe(() => this.selectItem())
    }

    ngOnDestroy() {}

    selectItem(index ?: number) {
        if (index == null) { index = this.selectedIndex + 1 } else { this.resetTimer$.next() }
        this.selectedIndex = index % this.items.length
    }
}

import {NgModule, Optional, SkipSelf} from '@angular/core'

import { throwIfAlreadyLoaded } from './module-import-guard'
import { TranslatorModule } from './translator/translator.module'

@NgModule({
    imports: [
        TranslatorModule
    ],
    declarations: [],
    exports: [
    ]
})
export class CoreModule {
    constructor(  @Optional()  @SkipSelf()  parentModule:  CoreModule)  {
        throwIfAlreadyLoaded(parentModule,  'CoreModule')
    }
}

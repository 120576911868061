import { Component } from '@angular/core'

import { NAV_CONFIG } from './header.constants'
import { NavItem, NavItemType } from './header.model'
import { AnalyticsService } from '../../../shared/services'
import { environment } from '../../../../environments/environment'
import { TranslatorService } from '../../../core/translator/translator.service'
import { State } from '../../../shared/components/morph-button/morph-button.model'

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
    navItemType = NavItemType
    navItems: NavItem[] = NAV_CONFIG
    headerIconUrl = environment.STATICS_URL_ASSETS + 'img/logo-header-mediapro.svg'
    languageIconUrl = environment.STATICS_URL_ASSETS + 'img/shape-language.svg'
    languages: Array<string>
    currentLang: string
    isCollapsed = true
    isCollapsedLanguage = true
    State = State

    constructor(
        public translatorService: TranslatorService,
        public analyticsServices: AnalyticsService) {

        this.languages = this.translatorService.getLanguages()
        this.currentLang = this.translatorService.getCurrentLang()
    }

    changeLang(lang: string) {
        this.translatorService.use(lang)
        this.currentLang = lang
    }

    trackLoginBtnClick() {
        this.analyticsServices.trackEvent('access_portal', 'click_login', 'Comercial pages access')
    }

    showMenu() {
        this.isCollapsed = !this.isCollapsed
    }
}

import { Component, Input } from '@angular/core'
import { environment } from '../../../../environments/environment'

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss'],
})

export class LoadingComponent {
    @Input() extraClasses: boolean
    urlLoaderImg = environment.STATICS_URL_ASSETS + 'img/loader_ball.gif'

    constructor() {}
}

import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'

import { throwError as observableThrowError } from 'rxjs'
import { environment } from '../../../environments/environment'
import { Product } from '../models/product'
import { Contact } from '../models/contact'
import { catchError, map } from 'rxjs/operators'

@Injectable()
export class MailService {

    constructor(private http: HttpClient) { }

    private handleError(error: Response) {
        console.error(error)
        return observableThrowError(error.text || ' error')
    }

    sendDemoRequest(valueObjectDemo: Product) {
        const body = JSON.stringify(valueObjectDemo)
        const head = new HttpHeaders({ 'Content-Type': 'application/json' })

        return this.http.post<any>(
            environment.API.SEND_DEMO_REQUEST,
            body,
            { headers: head }
        ).pipe(
            map(res => res),
            catchError(error => this.handleError(error))
        )
    }

    sendContactRequest(valueObjectDemo: Contact) {
        const body = JSON.stringify(valueObjectDemo)
        const head = new HttpHeaders({ 'Content-Type': 'application/json' })

        return this.http.post<any>(
            environment.API.SEND_CONTACT_REQUEST,
            body,
            { headers: head }
        ).pipe(
            map(res => res),
            catchError(error => this.handleError(error))
        )
    }
}

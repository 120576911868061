import { DomSanitizer } from '@angular/platform-browser'
import { Component, HostListener, ViewChild, ViewEncapsulation } from '@angular/core'

import * as moment from 'moment'


import { UtilitiesService } from '../../../shared/services'
import { DialogLegalComponent } from '../../../shared/components'
import { environment } from '../../../../environments/environment'
import { EnvironmentType } from '../../../../environments/environment.model'

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class FooterComponent {
    @ViewChild('privacy') public privacy: DialogLegalComponent
    @ViewChild('conditions') public conditions: DialogLegalComponent

    urlStatics: string
    environment: object = environment
    displayCondiciones = false
    displayButtonGoTop = false
    setMarginButton = false
    imgButtonGoTop = 'img/bt-goingtotop.svg'

    constructor(
        public utilitiesService: UtilitiesService,
        public _DomSanitizationService: DomSanitizer
    ) {
        this.urlStatics = utilitiesService.assets
    }

    goTo(location: string): void {
        window.location.hash = location
    }

    onClickGoTop() {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
    }

    onMouseOver() {
        this.imgButtonGoTop = 'img/shape.svg'
    }
    onMouseOut() {
        this.imgButtonGoTop = 'img/bt-goingtotop.svg'
    }

    @HostListener('window:scroll', [])
    onWindowScroll() {
        if (window.pageYOffset >= 600) {
            this.displayButtonGoTop = true
        } else {
            this.displayButtonGoTop = false
        }
    }

    openModal(type) {
        this[type].openModal()
    }

    addClassMarginRightInIE() {
        if (( navigator.userAgent.indexOf('MSIE') !== -1 ) || ( navigator.userAgent.indexOf('Trident') !== -1 )) {
            this.setMarginButton = true
        }
    }

    isEnvProduction() {
        return (environment.envType === EnvironmentType.Prod || environment.envType === EnvironmentType.Pr)
    }

    fmtDate(date) {
        // original format: 2017-11-21T16:53:56.798+01:00
        // expected format: 21-11-2017
        return moment(date).format('DD-MM-YYYY')
    }
}



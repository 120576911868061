
export const LEGAL_TEXT = {
    conditions: [
        {
            type: 'title',
            text: 'TERMS_AND_CONDITIONS_1_TITLE',
            items: [
                {
                    type: 'text',
                    text: 'TERMS_AND_CONDITIONS_1_TEXT_1'
                },
                {
                    type: 'text',
                    text: 'TERMS_AND_CONDITIONS_1_TEXT_2'
                },
                {
                    type: 'text',
                    text: 'TERMS_AND_CONDITIONS_1_TEXT_3'
                },
                {
                    type: 'text',
                    text: 'TERMS_AND_CONDITIONS_1_TEXT_4'
                },
                {
                    type: 'text',
                    text: 'TERMS_AND_CONDITIONS_1_TEXT_5'
                },
                {
                    type: 'text',
                    text: 'TERMS_AND_CONDITIONS_1_TEXT_6'
                },
                {
                    type: 'text',
                    text: 'TERMS_AND_CONDITIONS_1_TEXT_7'
                },
                {
                    type: 'text',
                    text: 'TERMS_AND_CONDITIONS_1_TEXT_8'
                },
            ]
        },
        {
            type: 'title',
            text: 'TERMS_AND_CONDITIONS_2_TITLE',
            items: [
                {
                    type: 'text',
                    text: 'TERMS_AND_CONDITIONS_2_TEXT_1'
                },
                {
                    type: 'text',
                    text: 'TERMS_AND_CONDITIONS_2_TEXT_2'
                },
                {
                    type: 'text',
                    text: 'TERMS_AND_CONDITIONS_2_TEXT_3'
                }
            ]
        },
        {
            type: 'title',
            text: 'TERMS_AND_CONDITIONS_3_TITLE',
            items: [
                {
                    type: 'text',
                    text: 'TERMS_AND_CONDITIONS_3_TEXT_1'
                },
                {
                    type: 'text',
                    text: 'TERMS_AND_CONDITIONS_3_TEXT_2'
                },
                {
                    type: 'subtitle',
                    text: 'TERMS_AND_CONDITIONS_3_1_TITLE',
                    items: [
                        {
                            type: 'text',
                            text: 'TERMS_AND_CONDITIONS_3_1_TEXT_1'
                        },
                        {
                            type: 'text',
                            text: 'TERMS_AND_CONDITIONS_3_1_TEXT_2'
                        },
                        {
                            type: 'text',
                            text: 'TERMS_AND_CONDITIONS_3_1_TEXT_3'
                        },
                    ]
                },
                {
                    type: 'subtitle',
                    text: 'TERMS_AND_CONDITIONS_3_2_TITLE',
                    items: [
                        {
                            type: 'text',
                            text: 'TERMS_AND_CONDITIONS_3_2_TEXT_1'
                        }
                    ]
                }
            ]
        },
        {
            type: 'title',
            text: 'TERMS_AND_CONDITIONS_4_TITLE',
            items: [
                {
                    type: 'text',
                    text: 'TERMS_AND_CONDITIONS_4_TEXT_1'
                },
                {
                    type: 'text',
                    text: 'TERMS_AND_CONDITIONS_4_TEXT_2'
                },
                {
                    type: 'text',
                    text: 'TERMS_AND_CONDITIONS_4_TEXT_3'
                },
                {
                    type: 'text',
                    text: 'TERMS_AND_CONDITIONS_4_TEXT_4'
                }
            ]
        },
        {
            type: 'title',
            text: 'TERMS_AND_CONDITIONS_5_TITLE',
            items: [
                {
                    type: 'subtitle',
                    text: 'TERMS_AND_CONDITIONS_5_1_TITLE',
                    items: [
                        {
                            type: 'text',
                            text: 'TERMS_AND_CONDITIONS_5_1_TEXT_1',
                        },
                        {
                            type: 'text',
                            text: 'TERMS_AND_CONDITIONS_5_1_TEXT_2',
                        },
                        {
                            type: 'text',
                            text: 'TERMS_AND_CONDITIONS_5_1_TEXT_3',
                        }
                    ]
                },
                {
                    type: 'subtitle',
                    text: 'TERMS_AND_CONDITIONS_5_2_TITLE',
                    items: [
                        {
                            type: 'text',
                            text: 'TERMS_AND_CONDITIONS_5_2_TEXT_1',
                        },
                        {
                            type: 'text',
                            text: 'TERMS_AND_CONDITIONS_5_2_TEXT_2',
                        },
                        {
                            type: 'text',
                            text: 'TERMS_AND_CONDITIONS_5_2_TEXT_3',
                        },
                        {
                            type: 'text',
                            text: 'TERMS_AND_CONDITIONS_5_2_TEXT_4',
                        },
                        {
                            type: 'text',
                            text: 'TERMS_AND_CONDITIONS_5_2_TEXT_5',
                        }
                    ]
                }
            ]
        },
        {
            type: 'title',
            text: 'TERMS_AND_CONDITIONS_6_TITLE',
            items: [
                {
                    type: 'text',
                    text: 'TERMS_AND_CONDITIONS_6_TEXT_1',
                },
                {
                    type: 'text',
                    text: 'TERMS_AND_CONDITIONS_6_TEXT_2',
                },
                {
                    type: 'text',
                    text: 'TERMS_AND_CONDITIONS_6_TEXT_3',
                },
                {
                    type: 'text',
                    text: 'TERMS_AND_CONDITIONS_6_TEXT_4',
                },
                {
                    type: 'text',
                    text: 'TERMS_AND_CONDITIONS_6_TEXT_5',
                },
                {
                    type: 'text',
                    text: 'TERMS_AND_CONDITIONS_6_TEXT_6',
                }
            ]
        },
        {
            type: 'title',
            text: 'TERMS_AND_CONDITIONS_7_TITLE',
            items: [
                {
                    type: 'text',
                    text: 'TERMS_AND_CONDITIONS_7_TEXT_1',
                },
                {
                    type: 'text',
                    text: 'TERMS_AND_CONDITIONS_7_TEXT_2',
                },
                {
                    type: 'text',
                    text: 'TERMS_AND_CONDITIONS_7_TEXT_3',
                },
                {
                    type: 'text',
                    text: 'TERMS_AND_CONDITIONS_7_TEXT_4',
                }
            ]
        },
        {
            type: 'title',
            text: 'TERMS_AND_CONDITIONS_8_TITLE',
            items: [
                {
                    type: 'text',
                    text: 'TERMS_AND_CONDITIONS_8_TEXT_1',
                },
                {
                    type: 'text',
                    text: 'TERMS_AND_CONDITIONS_8_TEXT_2',
                },
                {
                    type: 'text',
                    text: 'TERMS_AND_CONDITIONS_8_TEXT_3',
                }
            ]
        },
        {
            type: 'title',
            text: 'TERMS_AND_CONDITIONS_9_TITLE',
            items: [
                {
                    type: 'text',
                    text: 'TERMS_AND_CONDITIONS_9_TEXT_1',
                    items: [
                        {
                            type: 'list',
                            text: 'TERMS_AND_CONDITIONS_9_TEXT_2',
                        },
                        {
                            type: 'list',
                            text: 'TERMS_AND_CONDITIONS_9_TEXT_3',
                        }
                    ]
                },
                {
                    type: 'text',
                    text: 'TERMS_AND_CONDITIONS_9_TEXT_4',
                }
            ]
        },
        {
            type: 'title',
            text: 'TERMS_AND_CONDITIONS_10_TITLE',
            items: [
                {
                    type: 'text',
                    text: 'TERMS_AND_CONDITIONS_10_TEXT_1',
                }
            ]
        }
    ],
    privacy: [
        {
            type: 'text',
            text: 'PRIVACY_POLICY_TEXT'
        },
        {
            type: 'title',
            text: 'PRIVACY_POLICY_1_TITLE',
            items: [
                {
                    type: 'text',
                    text: 'PRIVACY_POLICY_1_TEXT_1',
                },
                {
                    type: 'text',
                    text: 'PRIVACY_POLICY_1_TEXT_2',
                }
            ]
        },
        {
            type: 'title',
            text: 'PRIVACY_POLICY_2_TITLE',
            items: [
                {
                    type: 'subtitle',
                    text: 'PRIVACY_POLICY_2_1_TITLE',
                    items: [
                        {
                            type: 'list',
                            text: 'PRIVACY_POLICY_2_1_TEXT_1',
                        },
                    ]
                },
                {
                    type: 'text',
                    text: 'PRIVACY_POLICY_2_TEXT_1',
                }
            ]
        },
        {
            type: 'title',
            text: 'PRIVACY_POLICY_3_TITLE',
            items: [
                {
                    type: 'text',
                    text: 'PRIVACY_POLICY_3_TEXT_1',
                },
                {
                    type: 'text',
                    text: 'PRIVACY_POLICY_3_TEXT_2',
                }
            ]
        },
        {
            type: 'title',
            text: 'PRIVACY_POLICY_4_TITLE',
            items: [
                {
                    type: 'text',
                    text: 'PRIVACY_POLICY_4_TEXT_1',
                },
                {
                    type: 'text',
                    text: 'PRIVACY_POLICY_4_TEXT_2',
                },
                {
                    type: 'text',
                    text: 'PRIVACY_POLICY_4_TEXT_3',
                }
            ]
        },
        {
            type: 'title',
            text: 'PRIVACY_POLICY_5_TITLE',
            items: [
                {
                    type: 'text',
                    text: 'PRIVACY_POLICY_5_TEXT_1',
                },
                {
                    type: 'subtitle',
                    text: 'PRIVACY_POLICY_5_1_TITLE',
                    items: [
                        {
                            type: 'text',
                            text: 'PRIVACY_POLICY_5_1_TEXT_1',
                        },
                        {
                            type: 'text',
                            text: 'PRIVACY_POLICY_5_1_TEXT_2',
                        },
                        {
                            type: 'text',
                            text: 'PRIVACY_POLICY_5_1_TEXT_3',
                        },
                        {
                            type: 'text',
                            text: 'PRIVACY_POLICY_5_1_TEXT_4',
                        },
                        {
                            type: 'text',
                            text: 'PRIVACY_POLICY_5_1_TEXT_5',
                        },
                        {
                            type: 'text',
                            text: 'PRIVACY_POLICY_5_1_TEXT_6',
                        },
                    ]
                },
                {
                    type: 'subtitle',
                    text: 'PRIVACY_POLICY_5_2_TITLE',
                    items: [
                        {
                            type: 'text',
                            text: 'PRIVACY_POLICY_5_2_TEXT_1',
                        },
                        {
                            type: 'text',
                            text: 'PRIVACY_POLICY_5_2_TEXT_2',
                        },
                        {
                            type: 'text',
                            text: 'PRIVACY_POLICY_5_2_TEXT_3',
                        },
                        {
                            type: 'text',
                            text: 'PRIVACY_POLICY_5_2_TEXT_4',
                        }
                    ]
                },
            ]
        }
    ]
}

import { Component, OnInit, Input } from '@angular/core'
import { LEGAL_TEXT } from './legal-template.constants'

@Component({
    selector: 'app-legal-template',
    templateUrl: './legal-template.component.html',
    styleUrls: ['./legal-template.component.scss']
})
export class LegalTemplateComponent implements OnInit {
    @Input() type
    @Input() title
    data = LEGAL_TEXT

    constructor() { }

    ngOnInit() { }

}

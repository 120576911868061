import { Inject, Injectable, OnDestroy } from '@angular/core'

import { Observable, Subscription } from 'rxjs'
import { fromEvent } from 'rxjs/observable/fromEvent'
import { untilDestroyed } from 'ngx-take-until-destroy'
import { DOCUMENT } from '@angular/common'

@Injectable()
export class ScrollService implements OnDestroy {
    private scrollSub: Subscription = new Subscription()
    private resizeSub: Subscription = new Subscription()

    scrollObs: Observable<any>
    resizeObs: Observable<any>
    pos: number

    constructor( @Inject(DOCUMENT) private document: Document) {
        // set initial value
        this._setViewportHeightVar()
        this._manageScrollPos()
        this._checkIfTouchable()

        // create observable for scrolling and resizing
        this.scrollObs = fromEvent(window, 'scroll')
        this.resizeObs = fromEvent(window, 'resize')

        // initiate subscription to update values
        this.scrollSub = this.scrollObs.pipe(untilDestroyed(this)).subscribe(() => this._manageScrollPos())
        this.resizeSub = this.resizeObs.pipe(untilDestroyed(this)).subscribe(() => {
            this._setViewportHeightVar()
            this._manageScrollPos()
            this._checkIfTouchable()
        })
    }

    private _checkIfTouchable() {
        this.document.body.classList[this._hasTouch() ? 'add' : 'remove']('has-touch')
    }

    private _hasTouch() {
        return 'ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0
    }

    private _setViewportHeightVar () {
        this.document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
    }

    private _manageScrollPos(): void {
        this.pos = window.pageYOffset
    }

    ngOnDestroy(): void {}
}

export * from './contact/contact.component'
export * from './contact-support/contact-support.component'
export * from './contact-us/contact-us.component'
export * from './cookies/cookies.component'
export * from './cookies-policy/cookies-policy.component'
export * from './dialogs/dialog-demo/dialog-demo.component'
export * from './footer/footer.component'
export * from './header/header.component'
export * from './home/home.component'
export * from './login/login.component'
export * from './page-not-found/page-not-found.component'
export * from './products/product-mediacoach-desktop/product-mediacoach-desktop.component'
export * from './products/product-mediacoach-live/product-mediacoach-live.component'
export * from './products/product-mediacoach-portal/product-mediacoach-portal.component'
export * from './products/products-home/products-center-home.component'
